<script>
import UserForm from "components/usuario/v2/components/UserForm.vue"
import Layout from "components/layout/Layout.vue"

export default {
  name: 'Usuario',
  inject: {
    erplayout: {
      default() {
        console.error('Main needs to be child of ErpLayout')
      }
    }
  },
  components: {
    Layout,
    UserForm
  },
  data() {
    return {
      binding: false
    }
  },
  watch: {
  },
  computed: {},
  mounted() {
  },
  destroyed() {
    // document.querySelector('.u-erp-layout-container').classList.remove('bg-white')
  },
  methods: {
    updateUser () {
      this.binding = true
      this.$forceUpdate()
      setTimeout(() => {
        this.binding = false
        this.$nextTick(() => {
          this.$forceUpdate()
        })
      }, 100)
    }
  },
  meta: {
    title: 'Usuário',
    name: 'Usuario'
  }
}
</script>

<template>
  <layout no-menu :folded="false" no-title hfull>
    <user-form v-if="!binding" @updateUser="updateUser" :user-id="$route.params.id" full />
  </layout>
</template>

